
































































































import { Component, Vue } from 'vue-property-decorator';
import UserAccountModule from '@/store/modules/UserAccountModule';
import AuthModule from '@/store/modules/AuthModule';
import accounterrors from '@/pages/account/components/AccountErrors.vue';

@Component({
  components: {
    accounterrors,
  },
})
export default class ChangeEmailPage extends Vue {
  newemail: string | null = null;
  showValidateMessage = false;

  get validateEmail() {
    if (this.newemail) {
      return this.isValidEmail(this.newemail!);
    } else if (this.showValidateMessage) {
      return false;
    } else {
      return null;
    }
  }

  //https://www.w3resource.com/javascript/form/email-validation.php
  isValidEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  async changeEmail() {
    if (this.validateEmail) {
      await UserAccountModule.changeEmail(this.newemail!);
      if (UserAccountModule.changeEmailSucceeded) {
        this.newemail = null;
      }
    }
  }

  get changeEmailError() {
    return UserAccountModule.changeEmailError;
  }

  get changeEmailProcessing() {
    return UserAccountModule.changeEmailProcessing;
  }

  get changeEmailSucceeded() {
    return UserAccountModule.changeEmailSucceeded;
  }

  get currentEmail() {
    return AuthModule.profile?.Email;
  }
}
